import type {
  GetVerificationIdStatusUseCase,
  KYCRepositoryContract,
  SubmitIDVerificationUseCase,
  VerificationIdData,
} from '../types';

export const getVerificationIdStatusUseCase: GetVerificationIdStatusUseCase = async (
  repository: KYCRepositoryContract,
) => {
  return repository.getVerificationIdStatus();
};

export const submitIDVerification: SubmitIDVerificationUseCase = async (
  repository: KYCRepositoryContract,
  data: VerificationIdData,
) => {
  return repository.submitIDVerification(data);
};
