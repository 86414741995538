import { resolveKYCRepository } from '@di/app';
import { VerificationIdData } from '../types';
import { getVerificationIdStatusUseCase, submitIDVerification } from '../use-cases/kyc.use-case';

/**
 * Wrap all related use cases in one resolver class
 * This would be clear in the auth resolver because it has multiple use cases
 */
export class KYCUseCaseResolver {
  /**
   * Resolve list countries use case dependencies and execute it
   */
  public static async getVerificationIdStatusUseCase(): ReturnType<
    typeof getVerificationIdStatusUseCase
  > {
    return getVerificationIdStatusUseCase(resolveKYCRepository());
  }

  public static async submitIDVerificationUseCase(
    data: VerificationIdData,
  ): ReturnType<typeof submitIDVerification> {
    return submitIDVerification(resolveKYCRepository(), data);
  }
}
